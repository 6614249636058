import React from 'react'
import styles from '../about.module.css'

const Steps = ({steps}) => {
    return(
        <div className="ui stackable three column centered grid">
            {steps.map((item, index)=>{
                return(
                    <div key={index} id={styles.step} className={`column`}>
                        <h1>#{index+1}</h1>
                        <h1> {item.title} </h1>
                        <p> {item.description} </p>
                    </div>
                )
            })}
        </div>
    )
}
export default Steps