import React from 'react'
import styles from './about.module.css'
import Features from './helpers/features';
import Propositions from './helpers/Propositions';
import Steps from './helpers/Steps';

const About = ({
    about,
    features,
    propositions,
    steps
}) => {
    return (
        <div>
            <section className={`header-parallax ${styles.hero}`}>
                <div className="container">
                    <h1>A propos de nous</h1>
                </div>
            </section>
            <section className={`container ${styles.about_section}`}>
                <div className="ui stackable two column grid">
                    <div className="column"><h1>{about.title}</h1></div>
                    <div className="column"><p>{about.description}</p></div>
                </div>
            </section>
            <section className={`${styles.features_section}`}>
                <div className="container">
                    <h1>Ce que nous proposons</h1>
                    <Features features={features} />
                </div>
            </section>
            <section className={`container ${styles.propositions_section}`}>
                <h1>Ce qu'on propose</h1>
                <Propositions propositions={propositions} />
            </section>

            <section className={`${styles.steps_section}`}>
                <div className="container">
                    <h1>Notre démarche</h1>
                    <Steps steps={steps} />
                </div>

            </section>
        </div>
    )
}
export default About