import React from 'react'
import styles from '../about.module.css'
import { Image } from 'semantic-ui-react';
import LazyLoad from 'react-lazyload'
import LazyPlaceHolder from '../../Comon/LazyPlaceHolder'

const Features = ({ features }) => {
    return (
        <div id={styles.features} className="ui two column stackable centered grid">
            {features.map((item, index) => {
                return (
                    <div key={index} className={`column ${styles.feature_col}`}>
                        <LazyLoad
                            height={300}
                            placeholder={<LazyPlaceHolder height={300} />}>
                            <div className={styles.image_container}>
                                <Image src={item.icon} size="tiny" circular />
                            </div>
                        </LazyLoad>
                        <div>
                            <h3>{item.name}</h3>
                            <p> {item.description} </p>
                        </div>
                    </div>
                )
            })
            }
        </div>
    )
}
export default Features