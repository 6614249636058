import React from 'react'
import { Card } from 'semantic-ui-react';
import styles from '../about.module.css'
import {cutString} from '../../Comon/utils'

const Propositions = ({ propositions }) => {
    return (
        <div className="ui stackable three column centered grid">
            {propositions.map((item, index) => {
                return (
                    <div key={index} className={`column ${styles.proposition_card}`}>
                        <Card fluid>
                            <img src={item.image} alt={item.name} />
                            <div className={styles.card_content}>
                                <h3> {item.name} </h3>
                                <p>{cutString(item.description, 100)}</p>
                            </div>
                        </Card>
                    </div>
                )
            })

            }
        </div>
    )
}
export default Propositions