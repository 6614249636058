import React from "react";
import { Placeholder } from 'semantic-ui-react'

const LazyPlaceHolder = ({height}) => {
  return (
    <Placeholder style={{ height }} fluid>
      <Placeholder.Image/>
    </Placeholder>
  );
};
export default LazyPlaceHolder;
