import React from 'react'
import Layout from '../components/Layout'
import About from '../components/About';
import { graphql } from 'gatsby'
import Helmet from "react-helmet";

const AboutPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark
    const {
        seo,
        about,
        features,
        propositions,
        steps
    } = frontmatter
    return (
        <Layout>
            <Helmet
                title={seo.seo_title}
                meta={[
                    {
                        name: "description",
                        content: seo.seo_metta_desc
                    }
                ]}
            />
            <About
                about={about}
                features={features}
                propositions={propositions}
                steps={steps}
            />
        </Layout>
    )
}
export default AboutPage
export const aboutPageQuery = graphql`
query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
        frontmatter{
            seo{
                seo_title
                seo_metta_desc
            }
            about{
                title
                description
            }
            features{
                name
                description
                icon
            }
            propositions{
                name
                description
                image
            }
            steps{
                title
                description
            }
        }
    }
    }
`